import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {showAdminSearchCouponTemplateModal} from '../../Components/AdminSearchCouponTemplateModal/index.js';

export default function AdminSelectCouponTemplateWidget(props) {
  const [couponTemplates] = useOutlet('coupon_templates');
  const [selected, setSelected] = React.useState(null);

  React.useEffect(() => {
    if (props.value && Array.isArray(couponTemplates)) {
      setSelected(couponTemplates.find((x) => x.id === props.value));
    }
  }, [props.value, couponTemplates]);

  return (
    <Wrapper>
      <div>
        <div>
          {!props.value
            ? '未設定優惠券樣板'
            : !selected
            ? props.value
            : `【 ${selected.name} 】`}
        </div>
        <div style={{fontSize: 10, color: '#bbb'}}>
          {selected && selected.id}
        </div>
      </div>

      <Button
        onClick={() => {
          props.onChange('');
          setSelected(null);
        }}
        style={{marginRight: 10}}>
        解除設定
      </Button>

      <Button
        type="primary"
        onClick={() =>
          showAdminSearchCouponTemplateModal({
            onChange: (id) => {
              props.onChange(id);
            },
          })
        }>
        搜尋
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  border: 1px solid #d9d9d9;
  padding: 15px;

  & > div {
    margin-right: 15px;
    letter-space: 1.5;
  }
`;
